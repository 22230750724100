import Avatar from "@grua/core/Avatar";
import Grid from "@grua/core/Grid";
import IconButton from "@grua/core/IconButton";
import List from "@grua/core/List";
import ListItem from "@grua/core/ListItem";
import ListItemIcon from "@grua/core/ListItemIcon";
import ListItemText from "@grua/core/ListItemText";
import Popover from "@grua/core/Popover";
import IconLogout from "@grua/icons/IconLogout";
import Divider from "@grua/core/Divider";
import User from "_common/@types/user";
import useStyles from "./MenuUsuarioStyle";

interface MenuUsuarioViewProps {
  anchorEl?: Element;
  user: User;
  handleUserProfile: () => void;
  handleMenuUsuario: (event: any) => void;
  handleCloseMenuUsuario: () => void;
  handleExit: () => void;
}

const MenuUsuarioView = ({
  anchorEl,
  user,
  handleUserProfile,
  handleMenuUsuario,
  handleCloseMenuUsuario,
  handleExit,
}: MenuUsuarioViewProps) => {
  const classes = useStyles();

  return (
    <>
      <IconButton onClick={handleMenuUsuario}>
        <Avatar name={user.name} src={user.avatar} />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleCloseMenuUsuario}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Grid container justifyContent="center" className={classes.root}>
          <Grid item xs={12}>
            <List disablePadding>
              <ListItem onClick={handleUserProfile} button>
                <ListItemIcon>
                  <Avatar name={user.name} src={user.avatar} />
                </ListItemIcon>
                <ListItemText
                  primary={user.name}
                  secondary={user.username}
                  secondaryTypographyProps={{ noWrap: true }}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={11}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <List disablePadding>
              <ListItem button onClick={handleExit}>
                <ListItemIcon>
                  <Avatar>
                    <IconLogout fontSize="small" />
                  </Avatar>
                </ListItemIcon>
                <ListItemText primary="Sair" />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default MenuUsuarioView;
