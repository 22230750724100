const { REACT_APP_AUTH_REDIRECT_URI, REACT_APP_AUTH_CLIENT_ID, REACT_APP_AUTH_URL, REACT_APP_LOGOUT_REDIRECT_URI } =
  process.env;

if ([REACT_APP_AUTH_REDIRECT_URI, REACT_APP_AUTH_CLIENT_ID, REACT_APP_AUTH_URL].includes(undefined)) {
  throw new Error("One or more environment variables do not have a specific value set");
}

const AuthorizationServerConfig = {
  get redirectUri() {
    return REACT_APP_AUTH_REDIRECT_URI as string;
  },
  get clientId() {
    return REACT_APP_AUTH_CLIENT_ID as string;
  },
  get url() {
    return REACT_APP_AUTH_URL as string;
  },
  get postLogoutRedirectUri() {
    return REACT_APP_LOGOUT_REDIRECT_URI as string;
  },
};

export default AuthorizationServerConfig;
