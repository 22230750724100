/* eslint-disable import/prefer-default-export */

export const PROVIDERS_DESCRIPTION = {
  NONE: "Provedor não atribuido",
  SIENGE_ID: "Sienge ID - Pessoal",
  SIENGE_ORG: "Sienge ID - Corporativo",
  MICROSOFT: "Microsoft",
  GOOGLE: "Google",
};
export const PROVIDERS_ENUM = {
  SIENGE_ID: "SIENGE_ID",
  SIENGE_ORG: "SIENGE_ORG",
  MICROSOFT: "MICROSOFT",
  GOOGLE: "GOOGLE",
  NONE: "NONE",
};
export const ORGANIZATION_ROLES = {
  OWNER: "Proprietário",
  ADMIN: "Administrador",
  USER: "Usuário padrão",
};
export const PROVIDERS_ASSETS = {
  SIENGE_ID: "logo-sienge-id.png",
  SIENGE_ORG: "logo-sienge-id.png",
  MICROSOFT: "ms-icon.png",
  GOOGLE: "google-icon.png",
};

// TODO: Remover após fazer ajuste do tslint no grua-config devido ao bug do no-shadow.
// https://github.com/typescript-eslint/tslint-to-eslint-config/issues/856
// eslint-disable-next-line no-shadow
export enum PROVIDER {
  MICROSOFT = "MICROSOFT",
  SIENGE_ID = "SIENGE_ID",
  SIENGE_ORG = "SIENGE_ORG",
  GOOGLE = "GOOGLE",
}
// eslint-disable-next-line no-shadow
export enum ROLE_LEVEL {
  USER = 1,
  ADMIN = 2,
  OWNER = 3,
}
// eslint-disable-next-line no-shadow
export enum ROLE {
  USER = "USER",
  ADMIN = "ADMIN",
  OWNER = "OWNER",
}

export const JWT_LOCALSTORAGE_KEY = "ssabt";
export const PKCE_ID_LOCALSTORAGE = `oauth2authcodepkce`;
export const PKCE_STATE_LOCALSTORAGE = `${PKCE_ID_LOCALSTORAGE}-state`;
export const REDIRECT_PATH_LOCALSTORAGE = "redirectPath";
export const PKCE_RECOMMENDED_CODE_VERIFIER_LENGTH = 96;
export const PKCE_RECOMMENDED_STATE_LENGTH = 32;
export const SP_IDP_HINT = "sp_idp_hint";
