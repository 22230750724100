/* eslint-disable camelcase */
import { api, authApi } from "services/api";
import { JWT_LOCALSTORAGE_KEY } from "_common/constants";
import { providersType } from "_common/@types/organizations";

const isUserAuthenticated = () => !!localStorage.getItem(JWT_LOCALSTORAGE_KEY);

type getTokenFnArgs = {
  username: string;
  password: string;
  client_id?: "account";
  grant_type?: "password";
  scope?: "default";
};

interface AccessCode {
  id_token: string;
  access_token: string;
}

type getTokenFn = (arg0: getTokenFnArgs) => Record<string, any>;

const getToken: getTokenFn = async ({
  username,
  password,
  client_id = "account",
  grant_type = "password",
  scope = "default",
}) => {
  const args: any = { username, password, client_id, grant_type, scope };
  const keys = Object.keys(args);
  const body = keys.map(key => `${key}=${encodeURIComponent(args[key])}`).join("&");

  const { data } = await api.post("oauth2/v1/token", body, {
    headers: { "content-type": "application/x-www-form-urlencoded" },
  });

  return data;
};

const getMyProfile = async () => {
  const { data } = await api.get("me/v1/profile");
  return data;
};

const configProviders = async (body: { id_token: string; access_token: string; provider: providersType }) => {
  const { data } = await authApi.post<{ token: string }>("/accounts/v1/me/providers-config", body);
  return data;
};

const getAccessToken = async (body: { provider: providersType; code: string }) => {
  const { data } = await authApi.post<AccessCode>("/login/oauth2/config/callback/google", body);
  return data;
};

const AuthService = {
  getToken,
  getMyProfile,
  isUserAuthenticated,
  configProviders,
  getAccessToken,
};

export default AuthService;
