import useAuthContext from "_common/hooks/useAuthContext";
import { useState } from "react";
import MenuUsuarioView from "./MenuUsuarioView";

const MenuUsuario = () => {
  const [anchorEl, setAnchorEl] = useState<Element>();
  const { user, signOut } = useAuthContext();
  const handleUserProfile = () => window.open(`${process.env.REACT_APP_ACCOUNT_URL}/auth/profile`, "_blank");
  const handleMenuUsuario = (event: any) => setAnchorEl(event.currentTarget);
  const handleCloseMenuUsuario = () => setAnchorEl(undefined);

  const handleExit = () => {
    signOut();
  };

  if (!user) return null;

  return (
    <MenuUsuarioView
      {...{ anchorEl, user, handleMenuUsuario, handleUserProfile, handleCloseMenuUsuario, handleExit }}
    />
  );
};

export default MenuUsuario;
