import AuthService from "modules/auth/services/AuthService";
import { createContext, useCallback, useLayoutEffect, useState } from "react";
import {
  JWT_LOCALSTORAGE_KEY,
  PKCE_ID_LOCALSTORAGE,
  PKCE_STATE_LOCALSTORAGE,
  REDIRECT_PATH_LOCALSTORAGE,
} from "_common/constants";
import useStorage from "_common/hooks/useStorage";
import User from "_common/@types/user";
import { generatePKCEArray, generatePKCECodes } from "utils/PkceUtil";

interface handleLoginOnOrganizationContextProps {
  organizationId: string;
  provider?: string;
}

export interface AuthContextType {
  user?: User;
  setUser: React.Dispatch<User>;
  isAuthenticated?: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  handleLoginOnOrganizationContext: (props: handleLoginOnOrganizationContextProps) => Promise<void>;
}

interface AuthContextProviderProps {
  children: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const AuthContext = createContext<AuthContextType>({
  setUser: () => {},
  setIsAuthenticated: () => {},
  handleLoginOnOrganizationContext: async () => {},
});

const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const storage = useStorage();
  const [user, setUser] = useState<User>();
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem(JWT_LOCALSTORAGE_KEY));

  const generateAuthorizationUrl = useCallback(
    async ({ organizationId, provider }: handleLoginOnOrganizationContextProps) => {
      const { codeChallenge, codeVerifier } = await generatePKCECodes();
      const stateQueryParam = generatePKCEArray();

      const params = {
        client_id: process.env.REACT_APP_AUTH_CLIENT_ID as string,
        redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI as string,
        scope: "openid",
        response_type: "code",
        response_mode: "fragment",
        code_challenge_method: "S256",
        code_challenge: codeChallenge,
        state: stateQueryParam,
      };

      if (provider) {
        Object.assign(params, { sp_idp_hint: provider });
      }

      if (user?.email) {
        Object.assign(params, { login_hint: user.email });
      }

      const query = new URLSearchParams(params).toString();

      storage.set(PKCE_ID_LOCALSTORAGE, codeVerifier);
      storage.set(PKCE_STATE_LOCALSTORAGE, stateQueryParam);

      return `${process.env.REACT_APP_AUTH_URL}/oauth2/${organizationId}/v1/authorize?${query}`;
    },
    [storage, user]
  );

  const handleLoginOnOrganizationContext = useCallback(
    async ({ organizationId, provider }: handleLoginOnOrganizationContextProps) => {
      const currentPath = window.location.pathname;
      const redirectPath = currentPath + window.location.search;
      localStorage.setItem(REDIRECT_PATH_LOCALSTORAGE, redirectPath);

      const authUrl = await generateAuthorizationUrl({ organizationId, provider });
      window.location.replace(authUrl);
    },
    [generateAuthorizationUrl]
  );

  useLayoutEffect(() => {
    const getUser = async () => {
      try {
        const data = await AuthService.getMyProfile();
        setUser(data);
      } catch {
        localStorage.removeItem(JWT_LOCALSTORAGE_KEY);
        setIsAuthenticated(false);
        document.location.reload();
      }
    };

    if (isAuthenticated && !user) getUser();
  }, [isAuthenticated, setUser, user, storage]);

  return (
    <AuthContext.Provider
      value={{ user, setUser, isAuthenticated, setIsAuthenticated, handleLoginOnOrganizationContext }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
