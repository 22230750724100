import { createContext, useCallback, useState } from "react";

export type snackbarVariant = "success" | "error" | "warning" | "info";

export type snackbarConfigType = {
  open: boolean;
  message: string;
  variant: snackbarVariant;
  options: Record<string, unknown>;
};

interface SnackbarContextProps {
  snackbarConfig: snackbarConfigType;
  onClose: () => void;
  dispatchSnackbar: (message: string, variant?: snackbarVariant, options?: any) => void;
}

interface SnackbarContextProviderProps {
  children: React.ReactNode;
}

const DEFAULT_SNACKBAR_CONFIG: snackbarConfigType = {
  open: false,
  message: "some message",
  variant: "info",
  options: {
    teste: true,
    arroz: "blabla",
  },
};

export const SnackbarContext = createContext<SnackbarContextProps>({
  snackbarConfig: DEFAULT_SNACKBAR_CONFIG,
  onClose: () => {},
  dispatchSnackbar: () => {},
});

const SnackbarContextProvider = ({ children }: SnackbarContextProviderProps) => {
  const [snackbarConfig, setSnackConfig] = useState(DEFAULT_SNACKBAR_CONFIG);

  const onClose = () => setSnackConfig(DEFAULT_SNACKBAR_CONFIG);

  const dispatchSnackbar = useCallback(
    (_message = "some message", _variant = "info" as snackbarVariant, _options = {}) => {
      setSnackConfig({ open: true, message: _message, variant: _variant, options: _options });
    },
    []
  );

  return (
    <SnackbarContext.Provider value={{ snackbarConfig, onClose, dispatchSnackbar }}>
      {children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarContextProvider;
