import LazyLoading from "@grua/core/LazyLoading";
import { lazy } from "react";
import { Redirect, Route } from "react-router-dom";
import PrivateRoute from "_common/components/PrivateRoute";
import FallbackSwitch from "_common/components/fallback-switch/fallback-switch";

const OrganizationsModuleRoutes = lazy(() => import("modules/organizations/OrganizationsModuleRoutes"));
const AuthModuleRoutes = lazy(() => import("modules/auth/AuthModuleRoutes"));

const MainRoutes = () => (
  <FallbackSwitch>
    <Route path="/auth" component={LazyLoading(AuthModuleRoutes)} />

    {/* rotas privadas */}
    <PrivateRoute path="/organizations" component={LazyLoading(OrganizationsModuleRoutes)} />

    {/* NAO ALTERAR A POSIÇÃO/CONFIGURAÇÃO ROTA "/" */}
    <Redirect to="/organizations" />
  </FallbackSwitch>
);

export default MainRoutes;
