import AuthService from "modules/auth/services/AuthService";
import { ComponentType } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import AuthContextProvider from "_common/contexts/authContext";
import MainLayout from "_common/layouts/Main";
import { REDIRECT_PATH_LOCALSTORAGE } from "_common/constants";

interface PrivateRouteProps {
  component: ComponentType<any>;
  layout?: (args: any) => JSX.Element;
  path: string;
  exact?: boolean;
}

const PrivateRoute = ({ component: Component, layout: Layout = MainLayout, ...rest }: PrivateRouteProps) => {
  const isUserAuthenticated = AuthService.isUserAuthenticated();
  const location = useLocation();

  if (!isUserAuthenticated) {
    const fullPath = location.pathname + location.search;
    localStorage.setItem(REDIRECT_PATH_LOCALSTORAGE, fullPath);
    return (
      <Redirect
        to={{
          pathname: "/auth/sign-in",
          state: { from: location },
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={matchProps => (
        <AuthContextProvider>
          <Layout>
            <Component {...matchProps} />
          </Layout>
        </AuthContextProvider>
      )}
    />
  );
};

export default PrivateRoute;
