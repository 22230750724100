import makeStyles from "@grua/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    paddingTop: theme.spacing(9),
    overflow: "hidden auto",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin-left"),
  },
}));

export default useStyles;
