import { useState } from "react";
import useStorage from "_common/hooks/useStorage";
import Header from "./Header";
import useStyles from "./MainStyle";

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout = ({ children }: MainLayoutProps) => {
  const storage = useStorage();
  const [sidebarOpened] = useState(storage.get("open-aside-menu") || false);
  const classes = useStyles({ sidebarOpened });

  return (
    <>
      <Header />
      {/* <Sidebar {...{ setSidebarOpened }} /> */}
      <main className={classes.root}>{children}</main>
    </>
  );
};

export default MainLayout;
