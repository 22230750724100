import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import storage from "utils/storage";
import { JWT_LOCALSTORAGE_KEY } from "_common/constants";

const { REACT_APP_SSO_API_URL, REACT_APP_AUTH_URL } = process.env;

export const api = axios.create({
  baseURL: REACT_APP_SSO_API_URL,
});

export const authApi = axios.create({
  baseURL: REACT_APP_AUTH_URL,
});

export const isApiError = axios.isAxiosError;

const interceptorRequestConfig = async (config: AxiosRequestConfig) => {
  const newConfig = { ...config };

  if (config.headers.Authorization === -1) {
    delete newConfig.headers.Authorization;
    return newConfig;
  }

  const keyToken = storage.get(JWT_LOCALSTORAGE_KEY);
  const token = keyToken ? keyToken?.access_token || null : null;

  if (token) newConfig.headers.Authorization = `Bearer ${token}`;

  return newConfig;
};

const interceptorResponseConfig = async (response: AxiosResponse<any>) => {
  if (response.status === 401) document.location.reload();

  return response;
};

// request interceptors
api.interceptors.request.use(interceptorRequestConfig);
authApi.interceptors.request.use(interceptorRequestConfig);

// response interceptros
api.interceptors.response.use(interceptorResponseConfig);
authApi.interceptors.response.use(interceptorResponseConfig);
