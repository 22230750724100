import GruaHeader from "@grua/core/Header";
import { memo } from "react";
import MenuUsuario from "./MenuUsuario";

const Header = () => {
  const logo = <img src="/assets/images/logotipo-sienge-id.png" alt="logo" height="40" />;
  const actions = [<MenuUsuario key="MenuUsuario" />];

  return <GruaHeader logo={logo} actions={actions} />;
};

export default memo(Header);
