/* eslint-disable no-console */
import { useContext } from "react";
import storage from "utils/storage";
import { JWT_LOCALSTORAGE_KEY } from "_common/constants";
import { AuthContext } from "_common/contexts/authContext";
import AuthorizationServerConfig from "modules/auth/SignIn/AuthorizationServerConfig";

const useAuthContext = () => {
  const authContextValues = useContext(AuthContext);

  const signOut = () => {
    authContextValues.setUser({});
    authContextValues.setIsAuthenticated(false);
    const { id_token: idToken } = storage.get(JWT_LOCALSTORAGE_KEY);
    storage.remove(JWT_LOCALSTORAGE_KEY);

    window.location.replace(
      `${AuthorizationServerConfig.url}/oidc/v1/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${AuthorizationServerConfig.postLogoutRedirectUri}`
    );
  };

  return { ...authContextValues, signOut };
};

export default useAuthContext;
