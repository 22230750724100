type storageT = "session" | "local";

const getStorage = (type: storageT) => {
  switch (type) {
    case "session":
      return sessionStorage;
    default:
      return localStorage;
  }
};

const useStorage = () => ({
  get: (key: string, storageType: storageT = "local") => {
    const value = getStorage(storageType).getItem(key);
    try {
      return value && JSON.parse(value);
    } catch {
      return value;
    }
  },
  set: (key: string, value: string | Record<string, unknown>, storageType: storageT = "local") => {
    getStorage(storageType).setItem(key, typeof value !== "string" ? JSON.stringify(value) : value);
  },
  remove: (key: string, storageType: storageT = "local") => getStorage(storageType).removeItem(key),
});

export default useStorage;
