/* eslint-disable no-plusplus */
import { PKCE_RECOMMENDED_CODE_VERIFIER_LENGTH } from "_common/constants";

const PKCE_CHARSET = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";

export const base64urlEncode = (value: string) => {
  let base64 = btoa(value);
  base64 = base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");

  return base64;
};

export const generatePKCEArray = (length: number = PKCE_RECOMMENDED_CODE_VERIFIER_LENGTH) => {
  const output = new Uint32Array(length);
  window?.crypto?.getRandomValues(output);

  const array = Array.from(output);
  const pkceArray = array.map((num: number) => PKCE_CHARSET[num % PKCE_CHARSET.length]);

  return pkceArray.join("");
};

export const generatePKCECodes = async () => {
  const codeVerifier = base64urlEncode(generatePKCEArray());
  const encondedCodeVerifier = await window?.crypto?.subtle?.digest("SHA-256", new TextEncoder().encode(codeVerifier));

  const hash = new Uint8Array(encondedCodeVerifier);
  const hashLength = hash.byteLength;

  let binary = "";
  for (let i = 0; i < hashLength; i++) {
    binary += String.fromCharCode(hash[i]);
  }

  const codeChallenge = base64urlEncode(binary);

  return { codeChallenge, codeVerifier };
};
