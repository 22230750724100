import makeStyles from "@grua/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.spacing(32),
    maxWidth: theme.spacing(32),
  },
}));

export default useStyles;
